import { IcebergAlert, IcebergIcon, IconButton, useSelectedTsKunde } from "@tradesolution/iceberg-ui-react"
import { Alert, Col, Form, InputGroup, Modal, Row, Spinner, Table } from "react-bootstrap"
import useForm from "../../../../utils/hooks/useForm";
import { useEffect, useState } from "react";
import BrukereApi from "../../../../Api/BrukereApi";
import { BrukerStorageLocationStatusDto, CreateBrukerCommand, UpdateBrukerTilgangerCommand } from "../../../../Api/BrukereApi/types";
import TilgangRow from "../TilgangRow";
import { getAppIcon } from "../../../../Components/IcebergIcon/utils";
import PermissionsApi from "../../../../Api/PermissionsApi";
import { Permission } from "../../../../Api/PermissionsApi/types";
import EmailsApi from "../../../../Api/EmailsApi";
import 'react-phone-number-input/style.css'
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import nb from 'react-phone-number-input/locale/nb.json'
import { E164Number } from 'libphonenumber-js/types.cjs';


interface Props {
  searchValue?: string;
  show: boolean;
  setShow: (value: boolean) => void;
  onUpdated: () => void;
}

interface NewPermission {
  role: string;
  accessTo: string;
  objectId: string;
}

const GiNyBrukerTilgang = (props: Props) => {

  const [checkedEmail, setCheckedEmail] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState<boolean>(false);
  const [existingUser, setExistingUser] = useState<BrukerStorageLocationStatusDto>();
  const { selectedTsKunde } = useSelectedTsKunde();
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [addedPermissions, setAddedPermissions] = useState<NewPermission[]>([]);
  const [isAddedPermissionsInvalid, setIsAddedPermissionsInvalid] = useState<boolean>(true);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);

  const initialValues: CreateBrukerCommand = {
    tsKundeId: selectedTsKunde.tsKundeId,
    givenName: '',
    surname: '',
    email: props.searchValue,
    tilgangCommands: []
  };

  const loadTilganger = async (objectId: string) => {
    const p = await PermissionsApi.getByObjectId(objectId, selectedTsKunde.tsKundeId);
    setPermissions(p);
  };

  const handleSearchEmail = async () => {
    setCheckedEmail(false);
    setIsSearching(prev => true);
    setExistingUser(null);
    setAddedPermissions([]);
    setPermissions([]);
    let existing: BrukerStorageLocationStatusDto;
    const searchResults = await BrukereApi.checkStorageLocationStatus(values.email);
    const existingUserIndex = searchResults.findIndex(x => x.email.toLowerCase() === values.email.toLowerCase());
    if (existingUserIndex > -1) {
      let result = searchResults[existingUserIndex];
      setExistingUser(result);
      existing = result;
      if (result.existsInDb === false) {
        values.givenName = result?.givenName;
        values.surname = result?.surname;
        values.mobile = result?.mobile;
        values.jobTitle = result?.jobTitle;
      }
    }
    const indexForUserWithEmailInProxyAdresses = searchResults.findIndex(x =>
      x.proxyAdresses.some(function (item) { return item.includes(values.email.toLowerCase()) }) &&
      x.email !== values.email);
    if (indexForUserWithEmailInProxyAdresses > -1) {
      let result = searchResults[indexForUserWithEmailInProxyAdresses];
      setExistingUser(result);
      existing = result;
    }
    if (existing) {
      setIsLoadingPermissions(true);
      loadTilganger(existing.objectId);
    }

    setIsLoadingPermissions(false);
    setCheckedEmail(true);
    setIsSearching(prev => false);
  };

  const validate = (values: CreateBrukerCommand) => {
    let errors: any = {};

    if (!values.email) {
      errors.email = 'E-post kan ikke være tomt';
    } else if (!isValidEmail(values.email)) {
      errors.email = 'E-post har ugyldig format';
    }

    if (!values.givenName) {
      errors.givenName = 'Fornavn kan ikke være tomt';
    }

    if (!values.surname) {
      errors.surname = 'Etterrnavn kan ikke være tomt';
    }

    if (values.mobile && !/^\d+$/.test(values.mobile)) {
      errors.mobile = 'Tlf nr må være numerisk';
    }

    if (isAddedPermissionsInvalid) {
      errors.permissions = 'Må ha minst en tilgang';
    }

    return errors;
  };

  const isValidEmail = (email: string) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  const addPermission = () => {
    const emptyPermission: NewPermission = { role: '', accessTo: '', objectId: existingUser?.objectId };
    setAddedPermissions([...addedPermissions, emptyPermission]);
  };

  const handleDeleteNewPermission = (index: number) => {
    setAddedPermissions(addedPermissions.filter((_, i) => i !== index));
  };

  const handleUpdateAccessTo = (index: number, value: string | undefined) => {
    if (value === undefined)
      return;
    const newPermissions = [...addedPermissions];
    newPermissions[index].accessTo = value;
    if (addedPermissions.some(x => x.accessTo)) {
      setIsAddedPermissionsInvalid(false);
    }
    else {
      setIsAddedPermissionsInvalid(true);
    }

    setAddedPermissions(newPermissions);
  };

  const handleUpdateRole = (index: number, value: string | undefined) => {
    if (value === undefined)
      return;
    const newPermissions = [...addedPermissions];
    newPermissions[index].role = value;
    setAddedPermissions(newPermissions);
  };

  const handleClose = () => {
    resetForm(undefined);
    props.setShow(false);
  };

  const onSubmit = async (command: CreateBrukerCommand) => {
    let tilganger = addedPermissions.filter(x => x.accessTo).map(x => ({ tsKundeId: selectedTsKunde.tsKundeId, accessTo: x.accessTo, role: x.role }))
    command.tilgangCommands = tilganger;
    await BrukereApi.createBruker(command);
    handleClose();
    resetForm(undefined);
    props.onUpdated();
  };

  const verifyEmail = async (email: string) => {
    setIsEmailValid(false);
    const verifyResult = await EmailsApi.verify(email);
    setIsEmailValid(verifyResult.canBeUsed);
  }

  const onTilgangerUpdatedSubmit = async () => {
    setIsLoadingPermissions(true);
    const updateCommand: UpdateBrukerTilgangerCommand = {
      brukerId: existingUser.objectId,
      onBehalfOfTsKundeId: selectedTsKunde.tsKundeId,
      tilgangCommands: [...addedPermissions.filter(x => x.accessTo), ...permissions]
    };
    await BrukereApi.updateBrukerTilgangerOnBehalfOf(updateCommand);
    handleClose();
    resetForm(undefined);
    props.onUpdated();
    setIsLoadingPermissions(false);
  };

  const { values, errors, touched, loading, handleChange, handleSubmit, resetForm, submitDisabled } = useForm(initialValues, validate, onSubmit);

  useEffect(() => {
    if (values && values.email && isValidEmail(values.email)) {
      handleSearchEmail();
    }
  }, [values?.email]);

  const clearSearch = () => {
    handleChange('email', '');
    setIsSearching(false);
    setExistingUser(null);
    setAddedPermissions([]);
    setPermissions([]);
    setCheckedEmail(false);
  };

  return (
    <>
      <Modal size="xl" centered show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Gi tilgang på vegne av {selectedTsKunde.tsKundeNavn}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>E-post</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      style={{
                        borderRight: 'hidden',
                        borderTopRightRadius: '24px 0px 0px 24px'
                      }}
                      value={values.email}
                      placeholder="Skriv inn e-posten du ønsker å gi tilganger til"
                      onChange={e => handleChange('email', e.target.value)}
                      onBlur={e => verifyEmail(e.target.value)}
                      isInvalid={(errors?.email && touched?.email) || !isEmailValid}
                      isValid={!errors.email && touched.email}
                    />
                    <InputGroup.Text style={{ borderLeft: 'hidden', borderRadius: '0px 24px 24px 0px' }}>
                      {values.email || isSearching ? (
                        <span onClick={clearSearch}>
                          <IcebergIcon
                          icon="close"
                          cursor="pointer"                          
                          />
                        </span>
                        
                      ) : (
                        <span onClick={handleSearchEmail}>
                          <IcebergIcon
                            icon="search"                          
                          />
                        </span>                        
                      )}
                    </InputGroup.Text>

                    <Form.Control.Feedback type="invalid">
                      {errors?.email}
                    </Form.Control.Feedback>
                    {!isEmailValid && (
                      <div className='invalid-feedback'>
                        <span>Det finnes ingen aktiv e-post konto med denne adressen. Har det sneket seg inn en skrivefeil? Kanskje noen har sluttet i jobben?</span>
                      </div>
                    )}
                  </InputGroup>

                </Form.Group>
              </Col>
            </Row>
            {checkedEmail && !existingUser?.existsInDb && (
              <>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <div>Vi trenger litt mer informasjon før vi kan gi {values.email} tilganger</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Fornavn</Form.Label>
                      <Form.Control type="text" value={values.givenName} onChange={e => handleChange('givenName', e.target.value)} isInvalid={errors?.givenName && touched?.givenName} />
                      <Form.Control.Feedback type="invalid">{errors?.givenName}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Etternavn</Form.Label>
                      <Form.Control type="text" value={values.surname} onChange={e => handleChange('surname', e.target.value)} isInvalid={errors?.surname && touched?.surname} />
                      <Form.Control.Feedback type="invalid">{errors?.surname}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Telefon</Form.Label>
                      <PhoneInputWithCountrySelect
                        labels={nb}
                        countryOptionsOrder={['NO', 'SE', 'DK']}
                        defaultCountry='NO'
                        className='form-control'
                        value={values.mobile}
                        onChange={(num: E164Number) => handleChange('mobile', num)} />
                      <Form.Control.Feedback type="invalid">
                        {errors?.mobile}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Tittel</Form.Label>
                      <Form.Control type="text" value={values.jobTitle} onChange={e => handleChange('jobTitle', e.target.value)} isInvalid={errors?.jobTitle && touched?.jobTitle} />
                      <Form.Control.Feedback type="invalid">{errors?.jobTitle}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            {
              checkedEmail && (
                <>
                  <Row>
                    <Col>
                      {
                        isLoadingPermissions && (
                          <span>Henter eksisterende tilganger... <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></span>
                        )
                      }
                      {
                        permissions.length > 0 && existingUser?.email && (
                          <Alert variant="info">{existingUser?.email} har allerede {permissions.length} tilgang(er). Legg gjerne til flere</Alert>
                        )
                      }
                      {
                        existingUser && existingUser.email?.toLowerCase() !== values?.email?.toLowerCase() && (
                          <Alert variant="info">E-posten du har søkt på tilhører {existingUser.email}. Alle tilganger blir derfor knyttet til denne e-posten</Alert>
                        )
                      }
                      <div className='table-responsive'>
                        <Table borderless hover >
                          <thead>

                            <>
                              <tr>
                                <td></td>
                                <td></td>
                                <td className='fitContent p-0'>
                                  <IconButton style={{ float: 'right' }} icon='plus' variant='outline-primary' onClick={addPermission} />
                                </td>
                              </tr>
                              <tr>
                                <th>Tilgang til</th>
                                <th>Rolle</th>
                                <th></th>
                              </tr>
                            </>

                          </thead>
                          <tbody>
                            {permissions.map((permission, index) => {
                              return <tr key={permission.id}>
                                <td><IcebergIcon icon={getAppIcon(permission.accessTo.toLowerCase())} />
                                  <span className='ms-1'>{permission.accessToDisplayName}</span> </td>
                                <td>{permission.roleDisplayName}</td>
                              </tr>
                            })}
                            {addedPermissions.map((permission, index) => {
                              return <TilgangRow
                                key={index}
                                permission={permission}
                                onUpdateAccessTo={(value) => handleUpdateAccessTo(index, value)}
                                onUpdateRole={(value) => handleUpdateRole(index, value)}
                                onDeleteRow={() => handleDeleteNewPermission(index)} />
                            })}
                          </tbody>
                          {permissions?.length + addedPermissions?.length > 3 && (
                            <tfoot>
                              <tr>
                                <td colSpan={4}>
                                  <IconButton icon='plus' variant='outline-primary' onClick={addPermission} />
                                </td>
                              </tr>
                            </tfoot>
                          )}
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <IconButton
              icon="close"
              variant="outline-primary"
              onClick={handleClose}
              className="col">
              Avbryt
            </IconButton>
            {!existingUser?.existsInDb && (
              <IconButton
                icon="disk"
                variant="primary"
                className="col"
                disabled={submitDisabled}
                type="submit">
                <span>
                  Lagre
                  {
                    loading &&
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  }
                </span>
              </IconButton>
            )}
            {existingUser?.existsInDb && (
              <IconButton
                icon="disk"
                variant="primary"
                className="col"
                disabled={isAddedPermissionsInvalid || isLoadingPermissions}
                onClick={onTilgangerUpdatedSubmit}>
                <span>
                  Lagre
                  {
                    isLoadingPermissions &&
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  }
                </span>
              </IconButton>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
};
export default GiNyBrukerTilgang;