import { IcebergAlert, IcebergIcon, IconButton, useSelectedTsKunde } from '@tradesolution/iceberg-ui-react';
import { useEffect, useRef, useState } from 'react';
import { Alert, Col, Form, InputGroup, Row, Spinner, Table } from 'react-bootstrap';
import { BrukerWithTilgangerDto } from '../../Api/BrukereApi/types';
import BrukereApi from '../../Api/BrukereApi';
import Style from "./index.module.css";
import BrukerTilgangerDropdown from './Components/BrukerTilgangerDropdown';
import EditBrukerTilganger from './Components/EditBrukerTilganger';
import useDebounce from '../../utils/hooks/useDebounce';
import GiNyBrukerTilgang from './Components/GiNyBrukerTIlgang';
import InfiniteScroll from "react-infinite-scroller/dist/InfiniteScroll";
import useIsMountedRef from '../../utils/hooks/useIsMountedRef';
import PermissionsApi from '../../Api/PermissionsApi';

const Brukere = () => {
    const [brukere, setBrukere] = useState<BrukerWithTilgangerDto[]>([]);
    const [selectedBruker, setSelectedBruker] =
        useState<BrukerWithTilgangerDto>();
    const [filter, setFilter] = useState({
        size: 25
    } as any);
    const {selectedTsKunde } = useSelectedTsKunde();
    const [showEditTilgangerModal, setShowEditTilgangerModal] = useState<boolean>(false);
    const [showGiNyBrukerTilgangModal, setShowGiNyBrukerTilgangModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSuperBruker, setIsSuberBruker] = useState<boolean>(false);
    const [queryText, setQueryText] = useState<string>("");
    const [queryTextToTransfer, setQueryTextToTransfer] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const [nextPageIsLoading, setNextPageIsLoading] = useState(false);
    useDebounce(() => setFilter({ ...filter, query: queryText }), 300, [
        queryText,
    ]);
    const isMountedRef = useIsMountedRef();
    const totalCount = useRef(0);    
    
    const hasSuperbrukerRole = async (tsKundeId: string) : Promise<boolean> => {        
        const p = await PermissionsApi.getMyPermissions(tsKundeId);
        var hasSuperbruker = p.some(x => x.role === "TsKundeBrukerAdmin" || x.role === "UberAdmin" || x.role === "TradesolutionAnsatt");        
        setIsSuberBruker(hasSuperbruker);
        return hasSuperbruker;
    };

    const loadBrukere = async (p: number) => {
        setIsLoading(true);
        var superBruker = await hasSuperbrukerRole(filter.onBehalfOfTsKundeId);   
        if (isMountedRef.current && superBruker) {
            const promise = BrukereApi.getBrukereCount(filter);
            const result = await BrukereApi.getBrukereWithPermissions(filter, p);
            totalCount.current = await promise;
            setIsLoading(false); 
            // if page > 1, just append to list
            if (p > 1) {
                setBrukere((prev) => [...prev, ...result]);
            } else {
                setBrukere(result);
            }
            setNextPageIsLoading(false);
        }
    };

    useEffect(() => {
        if (page > 1) {
            loadBrukere(page);
        }
    }, [page]);

    const handleLoadMore = () => {
        if (
            !isLoading &&
            brukere.length < totalCount.current &&
            isMountedRef.current &&
            !nextPageIsLoading
        ) {
            setNextPageIsLoading(true);
            setPage((prev) => prev + 1);
        }
    };

    const handleEditBrukerTilganger = (bruker: BrukerWithTilgangerDto) => {
        setSelectedBruker(bruker);
        setShowEditTilgangerModal(true);
    };

    const handleGiNyBrukerTilgang = () => {
        setShowGiNyBrukerTilgangModal(true);
        setQueryTextToTransfer("")
    };

    const handleGiNyBrukerTilgangFromSearch = () => {
        setShowGiNyBrukerTilgangModal(true);
        setQueryTextToTransfer(queryText);
    };

    const clearSearch = () => {
        setQueryText("");
    };
    
    useEffect(() => {       
        if(filter.onBehalfOfTsKundeId){            
            loadBrukere(1);
            setPage(1);
        }            
    }, [filter]);

    useEffect(() => {                 
        setFilter({...filter, onBehalfOfTsKundeId: selectedTsKunde?.tsKundeId || '' });
    }, [selectedTsKunde]);
    
    return (
        <>
            <div className='container-fluid'>
                {(!isSuperBruker) && (
                <Row style={{ marginTop: "0.5rem" }}>
                    <Col>
                        <Alert variant="info">
                            Du har ikke tilgang til brukere for {selectedTsKunde?.tsKundeNavn}. Ta kontakt med support@tradesolution.no hvis du mener at det er feil
                        </Alert>
                    </Col>
                </Row>                    
                )}
                {(isSuperBruker) && (
                    <>
                        <Row style={{ marginTop: "0.5rem" }}>
                            <Col className="mb-3 mt-3">
                                <h2 className='fw-bold' style={{ fontSize: '20px', textAlign: 'left' }}>Bruker tilganger</h2>                    
                            </Col>   
                            <Col className='text-end'>
                                <IconButton size="sm" variant='primary' icon="plus" onClick={() => handleGiNyBrukerTilgang()}>
                                    Gi ny bruker tilgang
                                </IconButton>
                            </Col>                         
                        </Row>
                        <Row >
                            <Col>
                                <InputGroup  className="mb-3" style={{width: '50%'}}>
                                    <Form.Control
                                        placeholder="Søk etter bruker"
                                        value={queryText}
                                        onChange={(e) => setQueryText(e.target.value)}
                                        style={{
                                            borderRight: "hidden",
                                            borderRadius: "24px 0px 0px 24px",
                                        }}
                                    />
                                    <InputGroup.Text
                                        style={{
                                            borderLeft: "hidden",
                                            borderRadius: "0px 24px 24px 0px",
                                        }}
                                    >
                                        {filter.query?.length > 0 ? (
                                            <span onClick={clearSearch}>
                                                <IcebergIcon
                                                icon="close"
                                                cursor="pointer"
                                            />
                                            </span>                                            
                                        ) : (
                                            <IcebergIcon icon="search" />
                                        )}
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>                            
                        </Row>
                        <Row>
                            <Col>
                                {(brukere.length === 0 && !isLoading && queryText) && (
                                    <IconButton size="sm" variant='outline-primary' icon="key" onClick={() => handleGiNyBrukerTilgangFromSearch()}>
                                        Vil du gi denne brukeren tilgang?
                                    </IconButton>
                                )}
                                {((brukere.length === 0) && !isLoading && !queryText) && (
                                    <Alert variant="info">
                                            Du har ikke tilgang til brukere for {selectedTsKunde?.tsKundeNavn}. Ta kontakt med support@tradesolution.no hvis du mener at det er feil
                                    </Alert>
                                )}
                                {(isLoading) && (
                                    <span>Henter brukere... <Spinner size='sm'/></span>
                                )}
                            </Col>
                        </Row>
                    </>                    
                )}
                {(brukere.length > 0 && isSuperBruker) && (
                    <Row>
                        <Col>
                            <InfiniteScroll
                                datalength={brukere.length}
                                pageStart={1}
                                loadMore={handleLoadMore}
                                hasMore={totalCount.current > brukere.length}
                                threshold={25}
                                initialLoad={false}
                                loader={
                                    <div key={0}>
                                        <span>Henter flere brukere... <Spinner size='sm'/></span>
                                    </div>
                                }
                            >
                                <Table responsive style={{ borderCollapse: 'collapse', borderTop: 'none' }} hover>
                                    <thead>
                                        <tr>
                                            <th className='d-none d-sm-table-cell'>Navn</th>
                                            <th>Kontaktinfo</th>  
                                            <th className='d-none d-md-table-cell'>Tilganger</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: 'none', border: '1px solid #DCDCDC' }}>
                                        {brukere.map((bruker, i) => <tr key={bruker.objectId}>
                                            <td className='d-none d-sm-table-cell'>
                                                <p>{bruker.givenName} {bruker.surname}</p>
                                                <p className={Style.tsKunde}>
                                                    {bruker.tsKundeNavn +
                                                        (bruker.jobTitle
                                                            ? " - " +
                                                            bruker.jobTitle.toUpperCase()
                                                            : "")}
                                                </p>
                                            </td>
                                            <td>
                                                <p className={Style.brukerNavn}>
                                                {bruker.email}
                                                </p>
                                                <p className={Style.tsKunde}>
                                                    {bruker.mobile}
                                                </p>
                                            </td>
                                            <td className='d-none d-md-table-cell'>   
                                                <BrukerTilgangerDropdown bruker={bruker} filterOnTsKundeId={selectedTsKunde.tsKundeId} />
                                            </td>                                                                
                                            <td className="fitContent">
                                                <IconButton size="sm" variant='outline-primary' icon="key" onClick={() => handleEditBrukerTilganger(bruker)}>
                                                    Tilganger
                                                </IconButton>
                                            </td>                                            
                                        </tr>)}
                                    </tbody>
                                </Table>
                            </InfiniteScroll>
                        </Col>
                    </Row>
                )} 
            </div>
            {showEditTilgangerModal && (
                <EditBrukerTilganger
                    bruker={selectedBruker}
                    show={showEditTilgangerModal}
                    setShow={setShowEditTilgangerModal}
                    onUpdated={() => loadBrukere(1)}
                />
            )}
            {showGiNyBrukerTilgangModal && (
                <GiNyBrukerTilgang
                    searchValue={queryTextToTransfer}
                    show={showGiNyBrukerTilgangModal}
                    setShow={setShowGiNyBrukerTilgangModal}
                    onUpdated={() => loadBrukere(1)}
                />
            )} 
        </>       
    )
};

export default Brukere;